
import REQ from '@/app/REQ'
import inputStr from '@/doc/inputStr.vue'
import inuptText from '@/doc/inuptText.vue'
import sel from '@/doc/sel.vue'
import selDate from '@/doc/selDate.vue'
import {classifyByProp, cloneObj, upWFoundById} from '@/utils/xyhUtils'
import elDragDialog from "@/directive/el-drag-dialog";
import dlgFoot from "@/generalComs/dlgFoot.vue"
import {DOC_ROLE, DOC_STATE, REMARK_STATE} from "@/consts";
import docItemRemarks from '@/doc/docItemRemarks.vue'
import currencyInput from "./currencyInput.vue";
import docItem from "./docItem.vue";
import docMix from "./docMix";
import {mapGetters} from "vuex";
import docEditState from "@/doc/docEditState";

function person(k){return {name: '', name_E:'',position: '',position_E:'',dept_E:'', dept: '', duty_E:'',duty: '', k,}}
function abbr(k){return {name: '',name_E:'', fullEn: '', fullCn: '', k,}}
function term(k){return {name: '',name_E:'',des_E:'', des: '', k,}}
function refLaw(k){return {name: '',name_E:'', issuer: '',issuer_E:'', num: '',num_E:'', date: '',date_E:'', k,}}
function refFlow(k){return {num: '',name: '',name_E:'', ver: '',ver_E:'', k,}}
function history(k){return {ver: '',ver_E:'',note_E:'', note: '', k,}}
function scope(k){return {name: '',name_E:'',des_E:'', des: '', k,}}
function purpose(k){return {content:'',content_E:'',k}}

export default {
    name: "libManual",
    components: {
        dlgFoot,
        'component-a': inputStr,
        'component-b': sel,
        'component-c': selDate,
        'component-d': inuptText,
        docItemRemarks,
        currencyInput,
        docItem,
    },
    directives: { elDragDialog },
    mixins:[docMix],


    data() {
        return {

            fd: ['purpose', 'scopeNote',],
            content: {
                authors: [
                    person(100),
                ],
                auditors: [
                    person(101),
                ],
                authorizers: [
                    person(102),
                ],
                duties: [
                    {role: '',role_E:'', des: [{k: 103, v: '',v_E:'',}], k: 104,},
                ],
                principles: [{name: '',name_E:'', des: [{v_E:'',v:'',k:105}], k: 106},],
                abbrs: [
                    abbr(107),
                ],
                terms: [
                    term(108),
                ],
                refLaws: [
                    refLaw(109),
                ],
                refFlows: [
                    refFlow(110),
                ],
                histories: [
                    history(111),
                ],
                scopes: [scope(112),],
                scopeNote: '',
                scopeNote_E:'',
                purposes: [purpose(113),],
                sDate: '',
                sDate_E: '',
                deptEdt: '',
                deptEdt_E: '',
                deptsRecv: '',
                deptsRecv_E:'',
            },
        }
    },

    methods: {
        Person(){return person(this.genNewK())},

        Abbr() {return abbr(this.genNewK())},

        Term() {return term(this.genNewK())},

        RefLaw() {return refLaw(this.genNewK())},

        RefFlow() {return refFlow(this.genNewK())},

        History() {return history(this.genNewK())},

        Scope() {return scope(this.genNewK())},

        Purpose(){return purpose(this.genNewK())},

        getDoc() {
            this.req2Data(REQ.docInLib, this.id, {doc: 'doc'}, ({docStaffs,doc, prjtStaffs,staffs}) => {
                if (doc.content) {
                    if(doc.kMax)this.kMax = doc.kMax
                    if (!doc.content.refLaws) {
                        doc.content.refLaws = [
                            {name: '',name_E:'', issuer: '',issuer_E:'', num: '',num_E:'', date: '',date_E:'', k: 109},
                        ]
                    }
                    if (!doc.content.refFlows) {
                        doc.content.refFlows = [
                            {num: '',num_E:'', name: '',name_E:'', ver: '',ver_E:'', k: 113},
                        ]
                    }
                    this.checkToInitK(doc.content)
                    this.content = doc.content
                }
                this.getDocs()
            })
        },
    }
};
