import { render, staticRenderFns } from "./libManual.vue?vue&type=template&id=6ba206c6&scoped=true&"
import script from "./libManual.js?vue&type=script&lang=js&"
export * from "./libManual.js?vue&type=script&lang=js&"
import style0 from "../assets/css/doc.less?vue&type=style&index=0&id=6ba206c6&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ba206c6",
  null
  
)

export default component.exports